import React, { useState } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"

// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation,Thumbs } from 'swiper';

// import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation,Thumbs]);

export default function TechList({pageContext: { node,list },location}) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            
            <div className="container mx-auto pt-[193px] pb-[105px] sm:pt-24 sm:pb-20">
                <div className="flex justify-between items-end pb-[88px] sm:flex-col sm:items-start sm:pb-14">
                    <h1 className="text-[40px] leading-snug">{node.title}</h1>
                    <ul className="flex items-center text-gray sm:justify-center sm:w-full sm:mt-10">
                    {
                        list.map((n,i,a)=>{
                            return (
                                <li key={i} className={`${i < a.length-1?'mr-[38px] sm:mr-5':''}`}><a className={`${n.url==node.url?'text-black':'hover:text-black'}`} href={n.url}>{n.title}</a></li>
                            )
                        })
                    }
                    </ul>
                </div>
                <Swiper 
                thumbs={{ swiper: thumbsSwiper,slideThumbActiveClass:"techActiveThumb" }}>
                    {
                        node.content.map((n,i,a)=>{
                            return <SwiperSlide key={i} className="flex items-start justify-between xl:flex-col">
                                        <h2 className="w-[278px] text-3xl leading-snug xl:w-full xl:mb-8">{n.title}</h2>
                                        <div className="w-[945px] xl:w-full">
                                            <article dangerouslySetInnerHTML={{__html: n.info}}/>
                                            <GatsbyImage className="w-full" image={getImage(n.image.localFile)} alt="product"/>
                                            <p className="pt-2.5">0{i+1}/0{a.length}</p>
                                        </div>
                                    </SwiperSlide>
                        })
                    }
                    
                    
                </Swiper>
                <Swiper 
                breakpoints={{
                    1280: {
                        spaceBetween: 50,
                        slidesPerView: 4
                    },
                    960: {
                        spaceBetween: 30,
                        slidesPerView: 3
                    },
                    769: {
                        spaceBetween: 20,
                        slidesPerView: 2
                    },
                    320: {
                        spaceBetween: 0,
                        slidesPerView: 1
                    }
                }}
                onSwiper={setThumbsSwiper} loop={true} className="mt-[132px] xl:mt-24 sm:mt-10"
                >

                    {
                        node.content.map((n,i)=>{
                            return <SwiperSlide key={i} className="py-[30px] px-5 text-3xl leading-snug text-[#999] hover:text-black relative !h-auto border-[6px] border-transparent hover:cursor-pointer after:content-none after:h-[6px] after:w-full after:bg-current after:absolute after:top-[-6px] after:left-0">
                                        {n.title}
                                    </SwiperSlide>
                        })
                    }
                </Swiper>
                
            </div>
            
            
        </Layout>
    )
}

